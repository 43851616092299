"use strict";

class Shop {
    static Tab = 'all';
    static Design = null;
    static Route = 'shop/browse';
    static CONTAINER_SELECTOR = '#browseProducts';

    static Initialize(tab = 'all', design = null) {
        Shop.Tab = tab;

        $('.shop-pagination').on('click', '.page-link', function(event) {
            if ($(this).closest('.page-item').hasClass('active')) {
                return false;
            }

            const href = $(this).attr('href');
            const url = new URL(href);
            const page = url.searchParams.get('page');

            // Activate page item
            $('.shop-pagination').find('.page-item.active').removeClass('active');
            $(this).closest('.page-item').addClass('active');

            // Replace window url
            window.history.replaceState({}, '', url);

            // Start loading
            UI.startLoadingOverlay();

            // Scroll to top
            $([document.documentElement, document.body]).animate({
                scrollTop: $(Shop.CONTAINER_SELECTOR).offset().top - 100
            }, 100);

            // Load results
            $.get(SiteURL + Shop.Route, {
                page: page,
                tab: Shop.Tab,
                design: Shop.Design,
                path: url.pathname
            }, function(resp) {
                if (resp.response) {
                    Shop.showResults(resp.resultsHtml);
                } else {
                    UI.handleJsonResponse(resp);
                }
            });
            
            return false;
        });
    }

    static showResults(resultsHtml) {
        const cont = $(Shop.CONTAINER_SELECTOR);
        let results = $(resultsHtml);
        results.find('.product').each(function(i, e) {
            $(e).hide();
        });
        cont.html('');
        cont.append(results);
        UI.stopLoadingOverlay();
        cont.find('.product').each(function(i, e) {
            $(e).fadeIn('fast');
        });
    }
}