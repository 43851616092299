"use strict";

class ExpressCheckout {
    static initialized = false;
    static stripe = null;
    static stripeElements = null;
    static expressCheckoutElement = null;
    static paymentAmount = null;
    static paymentCurrency = null;
    static shippingCountries = [];
    static shippingRates = [];
    static lineItems = [];
    static formData = null;
    static visibilityContainerId = 'expressCheckoutSection';
    static mountContainerId = 'expressCheckoutElement';
    static onFetchFormData = undefined;
    static onGetSelectedItemsCount = undefined;

    static Initialize(stripePublicKey, locale, shippingCountries) {
        ExpressCheckout.shippingCountries = shippingCountries;
        ExpressCheckout.expressCheckoutDiv = document.getElementById(ExpressCheckout.visibilityContainerId);
        ExpressCheckout.Loading(true);
        App.cachedScript('https://js.stripe.com/v3/').then(function() {
            ExpressCheckout.stripe = Stripe(stripePublicKey, { locale });
            ExpressCheckout.initialized = true;
            ExpressCheckout.Update();
        });
    }

    static Loading(state) {
        if (state) {
            ExpressCheckout.expressCheckoutDiv.style.visibility = 'initial';
            ExpressCheckout.expressCheckoutDiv.style.display = 'block';
            $('#' + ExpressCheckout.mountContainerId).addClass('loading');
        } else {
            $('#' + ExpressCheckout.mountContainerId).removeClass('loading');
        }
    }

    static PopulateData(data) {
        ExpressCheckout.paymentAmount = data.amount;
        ExpressCheckout.paymentCurrency = data.currency;
        ExpressCheckout.shippingRates = data.shippingRates;
        ExpressCheckout.lineItems = data.lineItems;
        ExpressCheckout.Update();
    }

    static Update() {
        if (!ExpressCheckout.initialized)
            return false;
        
        if (ExpressCheckout.onGetSelectedItemsCount != undefined && typeof ExpressCheckout.onGetSelectedItemsCount == 'function') {
            const selectedItemsCount = ExpressCheckout.onGetSelectedItemsCount();
            if (ExpressCheckout.initialized && selectedItemsCount <= 0) {
                ExpressCheckout.expressCheckoutDiv.style.visibility = 'hidden';
                ExpressCheckout.expressCheckoutDiv.style.display = 'none';
                return false;
            }
        }

        if (!ExpressCheckout.paymentAmount || !ExpressCheckout.paymentCurrency)
            return false;
        
        const options = {
            mode: 'payment',
            amount: ExpressCheckout.paymentAmount,
            currency: ExpressCheckout.paymentCurrency.toLowerCase(),
            appearance: {/*...*/},
        };

        // Set up Stripe.js and Elements to use in checkout form.
        if (ExpressCheckout.stripeElements) {
            ExpressCheckout.stripeElements.update(options);
        } else {
            ExpressCheckout.stripeElements = ExpressCheckout.stripe.elements(options);
        }
        
        // Create and mount the Express Checkout Element
        if (!ExpressCheckout.expressCheckoutElement) {
            ExpressCheckout.Loading(true);
            ExpressCheckout.expressCheckoutElement = ExpressCheckout.stripeElements.create('expressCheckout');
            ExpressCheckout.expressCheckoutElement.mount('#'+ExpressCheckout.mountContainerId);

            // Handle ready event
            ExpressCheckout.expressCheckoutElement.on('ready', ({ availablePaymentMethods }) => {
                if (!availablePaymentMethods) {
                    ExpressCheckout.expressCheckoutDiv.style.visibility = 'hidden';
                    ExpressCheckout.expressCheckoutDiv.style.display = 'none';
                } else {
                    // Optional: Animate in the Element
                    ExpressCheckout.expressCheckoutDiv.style.visibility = 'initial';
                    ExpressCheckout.expressCheckoutDiv.style.display = 'block';
                }
                ExpressCheckout.Loading(false);
            });

            // Handle loaderror event
            ExpressCheckout.expressCheckoutElement.on('loaderror', (event) => {
                ExpressCheckout.expressCheckoutElement.destroy();
                ExpressCheckout.expressCheckoutElement = null;
                ExpressCheckout.expressCheckoutDiv.style.visibility = 'hidden';
                ExpressCheckout.expressCheckoutDiv.style.display = 'none';
            });
            
            // Handle on click event
            ExpressCheckout.expressCheckoutElement.on('click', (event) => {
                ExpressCheckout.stripeElements.update({
                    amount: ExpressCheckout.paymentAmount,
                    currency: ExpressCheckout.paymentCurrency.toLowerCase(),
                });
                event.resolve({
                    emailRequired: true,
                    phoneNumberRequired: true,
                    shippingAddressRequired: true,
                    allowedShippingCountries: ExpressCheckout.shippingCountries,
                    shippingRates: ExpressCheckout.shippingRates,
                    lineItems: ExpressCheckout.lineItems,
                });
            });

            // Handle shipping address change event
            ExpressCheckout.expressCheckoutElement.on('shippingaddresschange', async (event) => {
                let formData = ExpressCheckout.GetFormData();
                formData.append('countryCode', event.address.country);
                App.post(BaseURL + 'shop/cart/calculate', formData, function(resp) {
                    if (resp.response) {
                        ExpressCheckout.stripeElements.update({
                            amount: resp.stripe.amount
                        });
                        event.resolve({
                            shippingRates: resp.stripe.shippingRates,
                            lineItems: resp.stripe.lineItems
                        });
                    } else {
                        event.reject();
                    }
                });
            });

            // Listen to the submit event to handle the payment
            ExpressCheckout.expressCheckoutElement.on('confirm', async (event) => {
                const { error: submitError } = await ExpressCheckout.stripeElements.submit();

                if (submitError) {
                    UI.error(submitError);
                    return;
                }

                let formData = ExpressCheckout.GetFormData();
                formData.append('expressPaymentType', event.expressPaymentType);
                formData.append('email', event.billingDetails.email);
                if (event.billingDetails.phone)
                    formData.append('phone', event.billingDetails.phone);
                formData.append('fullname', event.shippingAddress.name);
                if (event.shippingAddress.address.line1)
                    formData.append('streetAddress', event.shippingAddress.address.line1);
                if (event.shippingAddress.address.line2)
                    formData.append('streetAddress2', event.shippingAddress.address.line2);
                if (event.shippingAddress.address.city)
                    formData.append('city', event.shippingAddress.address.city);
                if (event.shippingAddress.address.state)
                    formData.append('stateOrRegion', event.shippingAddress.address.state);
                if (event.shippingAddress.address.postal_code)
                    formData.append('zipCode', event.shippingAddress.address.postal_code);
                formData.append('country', event.shippingAddress.address.country);
                formData.append('country_code', event.shippingAddress.address.country);
                formData.append('shippingMethodId', event.shippingRate.id);

                // Create the PaymentIntent and obtain clientSecret
                App.post(BaseURL + 'shop/checkout/express-checkout', formData, (resp) => {
                    if (resp.response) {
                        ExpressCheckout.stripe.confirmPayment({
                            // `elements` instance used to create the Express Checkout Element
                            elements: ExpressCheckout.stripeElements,
                            // `clientSecret` from the created PaymentIntent
                            clientSecret: resp.clientSecret,
                            confirmParams: {
                                return_url: SiteURL + 'shop/checkout/payment-pending'
                            },
                        }).then(function(result) {
                            if (result.error) {
                                UI.error(result.error.message);
                            }
                        });
                    } else {
                        UI.handleJsonResponse(resp);
                        event.paymentFailed({ reason: 'fail' });
                    }
                });
            });

            // Listen to the cancel event to detect when a customer dismisses the payment interface. Reset the amount to the initial amount.
            ExpressCheckout.expressCheckoutElement.on('cancel', () => {
                ExpressCheckout.expressCheckoutShippingAddress = null;
                ExpressCheckout.stripeElements.update({
                    amount: ExpressCheckout.paymentAmount,
                    currency: ExpressCheckout.paymentCurrency.toLowerCase(),
                });
            });
        }

        return true;
    }

    static GetFormData() {
        if (ExpressCheckout.onFetchFormData != undefined && typeof ExpressCheckout.onFetchFormData == 'function') {
            return ExpressCheckout.onFetchFormData();
        }

        return new FormData();
    }
}