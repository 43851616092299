"use strict";

class Cart {
    static Initialize() {
        $('#cartForm').on('submit', function(event) {
            const form = $(this);
            const btn = $('#proceedToCheckoutActionBtn');
            UI.buttonStartLoading(btn);
            App.post(BaseURL + 'shop/cart/checkout', form, function(resp) {
                UI.buttonStopLoading(btn);
                UI.handleJsonResponse(resp);
            });
            event.preventDefault();
            return false;
        });

        $('.cart-product-checkbox').on('change', function() {
            const id = $(this).val();
            const state = $(this).is(':checked');
            let engravingCheckbox = $('#engraving'+id+'Input');
            const url = new URL(window.location);

            if ($('[data-tied-with="'+id+'"]').length > 0) {
                $('[data-tied-with="'+id+'"]').prop('checked', state);
            }

            if ($('.cart-product-checkbox:checked').length > 0) {
                $('#proceedToCheckoutActionBtn').prop('disabled', false);
            } else {
                $('#proceedToCheckoutActionBtn').prop('disabled', true);
            }

            if (engravingCheckbox.length > 0) {
                if ($(this).is(':checked')) {
                    engravingCheckbox.prop('checked', true);
                } else {
                    engravingCheckbox.prop('checked', false);
                }
            }

            let unselected = [];
            $('.cart-product-checkbox:not(:checked)').each(function(i, el) {
                unselected.push($(el).val());
            });
            localStorage.setItem('cartUnselected', unselected.join(','));
        });

        $('[data-calculate-price]').on('change', function() {
            Cart.RecalculateCart();
        });

        $('[data-cart-remove]').on('click', function() {
            const btn = $(this);
            const id = parseInt(btn.attr('data-cart-remove'));
            App.post(BaseURL + 'shop/cart/remove', {
                item: id,
            }, function(resp) {
                if (resp.response) {
                    const element = $('[data-cart-item="'+id+'"]');
                    const cont = element.parent();
                    element.remove();
                    if (cont.children().length == 0) {
                        cont.append('<li class="no-records"><h5>'+Lang.Cart.yourCartIsEmpty+'</h5></li>');
                    }
                    UI.handleGtmEvents(resp);
                    Cart.RecalculateCart();
                } else {
                    UI.handleJsonResponse(resp);
                }
            });
            return false;
        });

        // Apply selected items state
        const unselected = localStorage.getItem('cartUnselected');

        if (unselected) {
            const unselectedItems = unselected.split(',');
            unselectedItems.forEach(itemId => {
                $('.cart-product-checkbox[value="'+itemId+'"]').prop('checked', false);
            });
        }

        if ($('.cart-product-checkbox:checked').length > 0) {
            $('#proceedToCheckoutActionBtn').prop('disabled', false);
        } else {
            $('#proceedToCheckoutActionBtn').prop('disabled', true);
        }
        
        ExpressCheckout.onFetchFormData = () => App.getFormData('#cartForm');
        ExpressCheckout.onGetSelectedItemsCount = () => $('.cart-product-checkbox:checked').length;
        Cart.RecalculateCart();
    }

    static RecalculateCart = () => {
        App.post(BaseURL + 'shop/cart/calculate', '#cartForm', function(resp) {
            if (resp.response) {
                $('#priceSubtotal').html(resp.displayPrices.subtotal);
                $('#priceShipping').html(resp.displayPrices.shipping);
                $('#taxPct').html(resp.displayPrices.taxPct);
                $('#priceTax').html(resp.displayPrices.tax);
                $('#priceTotal').html(resp.displayPrices.total);
                if (resp.prices.engravings > 0) {
                    $('#priceEngravings').html(resp.displayPrices.engravings);
                } else {
                    $('#priceEngravings').html('<b>-</b>');
                }
                $('.item-error').hide();
                for (const productId in resp.errors) {
                    const error = resp.errors[productId];
                    $('[data-product-id="'+productId+'"]').find('.item-error').html(error).show();
                }
                if (resp.stripe != undefined) {
                    ExpressCheckout.PopulateData(resp.stripe);
                }
                if (resp.shipping != undefined) {
                    if ($('#deliveryPeriod').length > 0) {
                        $('#deliveryPeriod').html(resp.shipping.periodFormatted);
                    }
                }
            }
        }, false);
    }
}