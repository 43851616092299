"use strict";

const AUTH_MFA_TYPE_EMAIL = 1;
const AUTH_MFA_TYPE_SMS = 2;
const AUTH_MFA_TYPE_GAUTH = 3;

const PAYMENT_METHOD_NONE = 0;
const PAYMENT_METHOD_STRIPE = 1;
const PAYMENT_METHOD_PAYPAL = 2;
const PAYMENT_METHOD_KLARNA = 3;
const PAYMENT_METHOD_METAMASK = 4;
const PAYMENT_METHOD_WALLETCONNECT = 5;
const PAYMENT_METHOD_SOFORT = 6;
const PAYMENT_METHOD_EXPRESS_CHECKOUT = 7;
const PAYMENT_METHOD_BANK_TRANSFER = 8;

const WALLET_CONNECTION_TYPE_METAMASK = 1;
const WALLET_CONNECTION_TYPE_WALLETCONNECT = 2;