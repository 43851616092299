"use strict";
class UI {
    static currentLoadingOverlay = null;
    static Initialize() { }
    /**
     * Display a modal box with password input.
     *
     * @param function onConfirm callback
     * @param object options
     */
    static authorizeModal(onConfirm, options) {
        const modal = $('#secureActionModal');
        const btn = modal.find('[type="submit"]');
        const errorCont = modal.find('.error-container');
        var settings = $.extend({}, {
            heading: 'Authentication required',
            text: 'Please enter your credentials to continue.',
            onHide: null,
            onCancel: null
        }, options);
        $('#secureActionForm').off('submit');
        $('#secureActionForm').on('submit', function (e) {
            const form = $(this);
            const password = form.find('[name="password"]').val();
            UI.buttonStartLoading(btn);
            App.post(BaseURL + 'authorize', { password }, function (response) {
                UI.buttonStopLoading(btn);
                if (response.response) {
                    settings.onCancel = null;
                    if (typeof onConfirm == 'function') {
                        onConfirm(response);
                    }
                    modal.modal('hide');
                }
                else {
                    if (typeof response.error != 'undefined') {
                        UI.alertError(response.error, errorCont);
                    }
                    else if (typeof response.errors != 'undefined') {
                        UI.errors(response.errors);
                    }
                }
            });
            e.preventDefault();
            return false;
        });
        modal.off('hide.bs.modal');
        modal.on('hide.bs.modal', function (e) {
            if (typeof settings.onHide == 'function') {
                settings.onHide();
            }
            if (typeof settings.onCancel == 'function') {
                settings.onCancel();
            }
        });
        modal.find('#secureActionTitle').html(settings.heading);
        modal.find('#secureActionText').html(settings.text);
        modal.modal('show');
    }
    static handleGtmEvents(response) {
        if (response.tagManagerEvent != undefined && response.tagManagerEvent != null) {
            if (response.tagManagerEvent.hasOwnProperty('ecommerce')) {
                dataLayer.push({ ecommerce: null });
            }
            dataLayer.push(response.tagManagerEvent);
        }
    }
    static handleJsonResponse(response, errorCont) {
        UI.handleGtmEvents(response);
        if (typeof response.redirect != 'undefined') {
            window.location.href = response.redirect;
        }
        else if (typeof response.toast != 'undefined') {
            for (var toastType in response.toast) {
                UI.toast(response.toast[toastType], toastType);
            }
        }
        else if (typeof response.error != 'undefined') {
            if (errorCont != undefined) {
                UI.alertError(response.error, errorCont);
            }
            else {
                UI.error(response.error);
            }
        }
        else if (typeof response.errors != 'undefined') {
            UI.fieldErrors(response.errors);
        }
        else if (typeof response.modal != 'undefined') {
            UI.modalMessage(response.modal.message, response.modal.title);
        }
    }
    static modalMessage(message, title) {
        if (title == undefined) {
            title = 'Undefined';
        }
        const modal = $('#messageModal');
        modal.find('#messageModalTitle').html(title);
        modal.find('#messageModalContent').html(message);
        modal.modal('show');
    }
    static error(message, title) {
        if (title == undefined) {
            title = 'An error has occurred';
        }
        UI.modalMessage(message, title);
    }
    static alert(message, type, cont) {
        if (type == undefined) {
            type = 'danger';
        }
        let container = (cont != undefined) ? $(cont) : $('.error-container');
        const errorEl = $('<div class="alert alert-' + type + ' alert-dismissible" role="alert">' +
            message +
            '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>' +
            '</div>');
        if (container != undefined) {
            container.prepend(errorEl);
            if (!App.isInViewport(errorEl[0])) {
                const offset = container.offset();
                if (offset != undefined) {
                    $('html, body').animate({
                        scrollTop: (offset.top - 100)
                    }, 500);
                }
            }
        }
    }
    static alertError(message, cont = null) {
        UI.alert(message, 'danger', cont);
    }
    static errors(errors) {
        for (var error in errors) {
            UI.alertError(errors[error]);
        }
    }
    static fieldErrors(errors) {
        let isFirst = true;
        for (var field in errors) {
            const error = errors[field];
            UI.fieldError(error, '[name="' + field + '"]', isFirst);
            isFirst = false;
        }
    }
    static fieldError(error, field, scrollTo = false) {
        let target = null;
        const formGroup = $(field).closest('.form-group');
        const formCheck = $(field).closest('.form-check');
        if (formGroup != undefined && formGroup.length > 0) {
            target = formGroup;
        } else if (formCheck != undefined && formCheck.length > 0) {
            target = formCheck;
        }
        if (target) {
            target.addClass('is-invalid');
            const feedbackCont = target.find('.invalid-feedback');
            if (feedbackCont.length > 0) {
                feedbackCont.html(error);
            }
            else {
                target.append('<div class="invalid-feedback">' + error + '</div>');
            }
            if (scrollTo && !App.isInViewport(target[0])) {
                const offset = target.offset();
                if (offset != undefined) {
                    $('html, body').animate({
                        scrollTop: (offset.top - 100)
                    }, 500);
                }
            }
        }
    }
    static clearFieldError(field) {
        $(field).closest('.form-group').removeClass('is-invalid');
        $(field).closest('.form-group').find('.invalid-feedback').html('');
    }
    static toast(message, type) {
        const cont = $('.toast-container');
        if (cont != undefined) {
            cont.append('<div class="toast toast-' + type + ' fade show" role="status" aria-live="polite" aria-atomic="true">' +
                '<div class="toast-body"><img src="' + BaseURL + 'assets/frontend/images/misc/toast-success-icon.svg" /> ' + message + '</div>' +
                '</div>');
            if (!App.isInViewport(cont[0])) {
                const offset = cont.offset();
                if (offset != undefined) {
                    $('html, body').animate({
                        scrollTop: (offset.top - 100)
                    }, 500);
                }
            }
        }
    }
    static successToast(message) {
        UI.toast(message, 'success');
    }
    static buttonStartLoading(btn, text) {
        if (text == undefined) {
            text = 'Loading...';
        }
        let width = $(btn).width();
        const widthPref = $(btn).attr('data-loading-width');
        if (widthPref && widthPref.length > 0 && widthPref == 'auto') {
            width = 'auto';
        }
        $(btn)
            .data('originalText', $(btn).html())
            .prop('disabled', true)
            .css('min-width', (width !== null) ? width : 'auto')
            .html(text);
    }
    static buttonStopLoading(btn) {
        $(btn)
            .html($(btn).data('originalText'))
            .css('min-width', 'auto')
            .prop('disabled', false);
    }
    static startLoadingOverlay(container) {
        $('body').addClass('has-loading-overlay');
        let overlay = $('<div class="loading-overlay">' +
            '<div class="overlay__inner">' +
                '<div class="overlay__content"><span class="spinner"></span></div>' +
            '</div>' +
        '</div>');
        if (container != undefined && container.length > 0) {
            $(container).append(overlay);
        } else {
            $('body').append(overlay);
        }
        UI.currentLoadingOverlay = overlay;
    }
    static stopLoadingOverlay(callback) {
        if (UI.currentLoadingOverlay && UI.currentLoadingOverlay.length > 0) {
            UI.currentLoadingOverlay.fadeOut('fast', function () {
                UI.currentLoadingOverlay.remove();
                UI.currentLoadingOverlay = null;
                if (typeof callback == 'function') {
                    callback();
                }
                $('body').removeClass('has-loading-overlay');
            });
        }
    }
    static buttonCooldown(btn, duration) {
        const width = $(btn).width();
        $(btn)
            .data('originalText', $(btn).html())
            .data('cooldown', duration)
            .prop('disabled', true)
            .css('width', width !== null && width !== void 0 ? width : 'auto')
            .html(duration.toString());
        let cdInterval = setInterval(function () {
            const cd = $(btn).data('cooldown') - 1;
            $(btn).data('cooldown', cd).html(cd.toString());
            if (cd <= 0) {
                clearInterval(cdInterval);
                $(btn)
                    .html($(btn).data('originalText'))
                    .css('width', $(btn).hasClass('w-100') ? '100%' : 'auto')
                    .prop('disabled', false);
            }
        }, 1000);
    }
    static enableSubmitByFill(formSelector) {
        $(formSelector).addClass('enable-by-fill');
        let fields = $(formSelector).find('.fill-required');
        const update = (elements) => {
            let hasEmpty = false;
            elements.each((i, element) => {
                if ($(element).prop("tagName").toLowerCase() == 'input') {
                    const type = $(element).attr('type');
                    if (type != undefined && type.length > 0) {
                        switch (type) {
                            case 'radio':
                            case 'checkbox':
                                const checkedOptions = $(formSelector).find('[name="'+$(element).attr('name')+'"]:checked');
                                if (checkedOptions.length == 0) {
                                    hasEmpty = true;
                                    return;
                                }
                                break;
                        }
                    }
                }
                if ($(element).val() == null || $(element).val() == '') {
                    hasEmpty = true;
                }
            });
            if (!hasEmpty) {
                $(formSelector).addClass('filled');
                $(formSelector).find('[type="submit"]').prop('disabled', false);
            } else {
                $(formSelector).removeClass('filled');
                $(formSelector).find('[type="submit"]').prop('disabled', true);
            }
        };
        update(fields);
        fields.each((i, element) => {
            const tagName = $(element).prop("tagName").toLowerCase();
            if (tagName == 'select') {
                $(element).on('change', (e) => {
                    update(fields);
                });
            }
            else if (tagName == 'textarea') {
                $(element).on('keyup change blur', function (e) {
                    update(fields);
                });
            }
            else if (tagName == 'input') {
                const type = $(element).attr('type');
                if (type != undefined && type.length > 0) {
                    switch (type) {
                        case 'text':
                        case 'email':
                        case 'password':
                        case 'number':
                        case 'date':
                        case 'tel':
                            $(element).on('keyup change blur', function (e) {
                                update(fields);
                            });
                            break;
                        case 'hidden':
                        case 'radio':
                        case 'checkbox':
                            $(element).on('change', function (e) {
                                update(fields);
                            });
                            break;
                    }
                }
            }
        });
    }
}
(function () {
    UI.Initialize();
})();