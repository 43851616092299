"use strict";

class MMLightweightChart {
    constructor(options) {
        const chart = this;
        this.settings = null;
        this.currencySymbols = {
            'usd': '$', // US Dollar
            'eur': '€', // Euro
            'crc': '₡', // Costa Rican Colón
            'gbp': '£', // British Pound Sterling
            'ils': '₪', // Israeli New Sheqel
            'inr': '₹', // Indian Rupee
            'jpy': '¥', // Japanese Yen
            'krw': '₩', // South Korean Won
            'ngn': '₦', // Nigerian Naira
            'php': '₱', // Philippine Peso
            'pln': 'zł', // Polish Zloty
            'pyg': '₲', // Paraguayan Guarani
            'thb': '฿', // Thai Baht
            'uah': '₴', // Ukrainian Hryvnia
            'vnd': '₫', // Vietnamese Dong
            'eth': 'ETH', // Ethereum
            'btc': 'BTC', // Bitcoin
        };
        this.series = [];
        
        const defaults = {
            selector: null,
            type: 'single-line',
            symbol: 'os',
            symbols: [],
            currencies: [ 'eur', 'usd', 'eth', 'btc' ],
            source: 1,
            variant: 1,
            variants: [],
            singleYAxis: false,
            axisNameFormat: '[symbol] [variant]',
            legendLabelFormat: '[name] ' + Lang.General.price,
            endpointUrl: BaseURL + 'chart-data',
        };

        this.settings = $.extend({}, defaults, options);
        if (typeof this.settings.symbols === 'string') {
            this.settings.symbols = [ this.settings.symbols ];
        }
        if (typeof this.settings.currencies === 'string') {
            this.settings.currencies = [ this.settings.currencies ];
        }
        if (typeof this.settings.variants === 'string') {
            this.settings.variants = [ this.settings.variants ];
        }

        // Initialize the chart
        this.lightweightChart = LightweightCharts.createChart($(this.settings.selector)[0], { autoSize: true });
        this.reload();

        let form = $('<form method="post" id="'+this.settings.selector.slice(1)+'Form">' +
                '<div class="d-flex flex-row align-items-start justify-content-start" style="gap: 22px">' +
                    '<div class="mb-3">' +
                        '<label class="form-label fw-bold">'+Lang.General.currency+'</label>' +
                        '<div class="chart-filter-currencies"></div>' +
                    '</div>' +
                '</div>' +
            '</form>');
        form.insertBefore(this.settings.selector);

        const selectedCurrency = this.getSelectedCurrency();
        this.settings.currencies.forEach((currency, i) => {
            form.find('.chart-filter-currencies').append('<div class="form-check form-check-inline">' +
                '<input class="form-check-input" type="radio" name="currency" id="currency_'+currency+'" value="'+currency+'"'+(selectedCurrency == currency ? ' checked' : '')+'>' +
                '<label class="form-check-label" for="currency_'+currency+'">'+currency.toUpperCase()+'</label>' +
            '</div>');
        });
        this.currencySymbol = this.getCurrencySymbol(selectedCurrency);
        form.on('change', '[name="currency"]', function(e) {
            let selected = $('[name="currency"]:checked').val();
            chart.setClientSetting('selectedCurrency', selected);
            chart.reload();
        });

        if (this.settings.currencies.length > 1) {
            form.find('.chart-filter-currencies').parent().show();
        } else {
            form.find('.chart-filter-currencies').parent().hide();
        }
    }

    getCurrencySymbol(currency) {
        if (this.currencySymbols.hasOwnProperty(currency.toLowerCase())) {
            return this.currencySymbols[currency.toLowerCase()];
        }
        return this.currencySymbols['eur'];
    }

    isComparison() {
        return this.settings.type == 'source-comparison';
    }

    isAreaChart() {
        return this.settings.type == 'single-area' || this.settings.type == 'multi-area' || this.settings.type == 'source-comparison';
    }

    getColors() {
        return this.isComparison() ? 
            [ '#000000', '#0188B7' ] :
            [ "#2caffe", "#544fc5", "#00e272", "#fe6a35", "#6b8abc", "#d568fb", "#2ee0ca", "#fa4b42", "#feb56a", "#91e8e1" ];
    }

    isMultiYAxis() {
        return (!this.isComparison() && !this.settings.singleYAxis && (this.settings.symbols.length > 1 || this.settings.variants.length > 1));
    }

    getSelectedCurrency() {
        return this.getClientSetting('selectedCurrency') ?? this.settings.currencies[0];
    }

    getClientSetting(name) {
        const key = this.settings.selector + '-' + name;
        if (localStorage.getItem(key) != undefined) {
            const value = localStorage.getItem(key);
            let parsedValue = null;
            try {
                parsedValue = JSON.parse(value);
            } catch (e) {
                // is not a valid JSON string
                return value;
            }
            return (parsedValue != null) ? parsedValue : value;
        }
        
        return null;
    }

    setClientSetting(name, value) {
        const key = this.settings.selector + '-' + name;
        localStorage.setItem(key, JSON.stringify(value));
    }

    capitalizeFirstLetter(str) {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }

    async reload() {
        const chart = this;
        let params = {
            type: chart.settings.type,
            symbol: chart.settings.symbol,
            symbols: chart.settings.symbols,
            currency: chart.getSelectedCurrency(),
            source: chart.settings.source,
            variant: chart.settings.variant,
            variants: chart.settings.variants,
            axisNameFormat: chart.settings.axisNameFormat,
            chartLibrary: 'lightweight',
        };
        $(chart.settings.selector).addClass('chart-loading');
        chart.series.forEach(s => {
            chart.lightweightChart.removeSeries(s);
        });
        $.get(chart.settings.endpointUrl+'?'+$.param(params), (resp) => {
            if (resp.response) {
                chart.currencySymbol = chart.getCurrencySymbol(resp.currency);
                chart.initializeSeries(resp.series);
                //chart.lightweightChart.timeScale().fitContent();
                // Apply the custom priceFormatter to the chart
                chart.lightweightChart.applyOptions({
                    localization: {
                        priceFormatter: (p) => { return `${(resp.currency == 'eth' ? p : p.toFixed(2))} ${chart.currencySymbol}`; },
                    },
                });
            }
            $(chart.settings.selector).removeClass('chart-loading');
        });
    }

    initializeSeries(rawSeries) {
        const chart = this;
        for (let key in rawSeries) {
            let symbol = rawSeries[key].symbol;
            const lineSeries = chart.lightweightChart.addLineSeries({ color: rawSeries[key].color });
            lineSeries.setData(rawSeries[key].data);
            lineSeries.priceScale().applyOptions({
                autoScale: true,
                /*scaleMargins: {
                    top: 0.2,
                    bottom: 0.1,
                },*/
            });
            chart.series.push(lineSeries);
            /*series.push({
                yAxis: chart.isMultiYAxis() ? index : 0,
                type: chart.isAreaChart() ? 'area' : 'line',
                name: rawSeries[key].name,
                data: rawSeries[key].data,
                allowPointSelect: true,
                zones: [
                    {
                        fillColor: {
                            linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                            stops: [
                                [0, Highcharts.color(chart.getColors()[index]).setOpacity(0.2).get('rgba')],
                                [1, Highcharts.color(chart.getColors()[index]).setOpacity(0).get('rgba')]
                            ],
                        },
                    }
                ],
                tooltip: {
                    valueDecimals: rawSeries[key].valueDecimals,
                    valueSuffix: (symbol === 'set' ? ` ${chart.currencySymbol}` : ` ${chart.currencySymbol}/${rawSeries[key].weightUnit}`),
                },
                visible: true,
            });
            // Multiple Y axis
            if (chart.isMultiYAxis()) {
                yAxis.push({
                    labels: {
                        enabled: false,
                    },
                    title: {
                        enabled: false,
                    },
                    gridLineColor: '#B7BDC7',
                    gridLineDashStyle: 'dash',
                });
            }
            index++;*/
        }
        // Single Y axis
        /*if (!chart.isMultiYAxis()) {
            yAxis.push({
                labels: {
                    enabled: true,
                    //align: 'right',
                    //x: 0,
                    //y: 0,
                    style: {
                        color: '#B7BDC7'
                    }
                },
                title: {
                    text: (Object.values(rawSeries)[0].symbol === 'set' ? ` ${chart.currencySymbol}` : `${chart.currencySymbol}/${Object.values(rawSeries)[0].weightUnit}`),
                    style: {
                        color: '#B7BDC7'
                    }
                },
                gridLineColor: '#B7BDC7',
                gridLineDashStyle: 'dash',
                opposite: false,
            });
        }*/
    }
}